<template>
    <div id="production" :class="[$mq]">
        <div id="sidebar">
            <Sidebar :categories="categories" :date="date" :summary="summary" @selectDate="selectDate" @selectCategory="selectCategory" :expanded="forecast && forecast.defrost !== false && !configuration" :selected="!forecast" :reset="!configuration"></Sidebar>
        </div>

        <div id="board">
            <header class="header-container" v-if="!bSelectDate && !configuration">
                <div class="production-selector">
                    <Tabs tabOneText="PREPARACIÓN" tabTwoText="ATEMPERO" tabThreeText="DESCONGELACIÓN" @switchClick="switchFilter" :selected="tabValue"></Tabs>
                </div>
            </header>
            <div class="main-content" :class="{ 'without-tab': bSelectDate || configuration }">
                <Configuration v-if="configuration" :types="[1, 2, 3, 4, 5]" />
                <ProductionInit v-else-if="bSelectDate" :forecast="forecast"></ProductionInit>
                <preparationView v-else-if="tabValue == 'tabOne'" :date="date" :products="products"></preparationView>
                <temperingView v-else-if="tabValue == 'tabTwo'" :date="date" :products="products"></temperingView>
                <defrostView v-else-if="tabValue == 'tabThree'" :date="date" :products="products"></defrostView>
                <article v-else class="working-on">
                    <span class="image"></span>
                    <h6>Próximamente...</h6>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from '../components/domain/production/sidebar.vue'
import Configuration from '../components/domain/production/Configuration.vue'
import ProductionInit from '../components/domain/production/ProductionInit.vue'
import Tabs from '../components/domain/ui/Tabs.vue'
import preparationView from '../components/domain/production/preparationView.vue'
import temperingView from '../components/domain/production/temperingView.vue'
import defrostView from '../components/domain/production/defrostView.vue'
import TemperingView from '../components/domain/production/temperingView.vue'
import { update } from 'lodash'
import { filter } from 'lodash'

export default {
    components: {
        Sidebar,
        Configuration,
        ProductionInit,
        Tabs,
        preparationView,
        temperingView,
        defrostView
    },
    name: 'Production',
    data() {
        return {
            date: moment().format('YYYY-MM-DD'),
            isExpanded: false,
            tabValue: 'tabOne'
        }
    },
    computed: {
        categories() {
            var types = []
            switch (this.tabValue) {
                case 'tabOne':
                    types = [1, 2, 3]
                    break
                case 'tabTwo':
                    types = [3, 5]
                    break
                case 'tabThree':
                    types = [2, 4]
                    break
            }
            const allProducts = this.$store.getters['production/getProducts']
            var filteredProducts = filter(allProducts, (product) => types.includes(product.type))

            const categories = this.$store.getters['production/getCategories']
            const productsCategories = Object.values(filteredProducts).map((product) => product.category)
            return Object.values(categories).reduce((acc, category) => {
                if (productsCategories.includes(category.id)) {
                    acc[category.id] = category
                }
                return acc
            }, {})
        },
        category() {
            return this.$store.getters['production/getSelectedCategory']
        },
        products() {
            let data = this.$store.getters['production/getProducts']
            let products = []
            for (var idx in data) {
                var product = data[idx]
                let show = false
                if (!this.category) {
                    show = true
                } else if (this.category && product.category == this.category.id) {
                    show = true
                }

                if (show && product.status) {
                    products.push(product)
                }
            }
            return products
        },
        forecast() {
            let forecast = this.$store.getters['production/getForecast']
            if (!forecast) {
                forecast = { defrost: false }
            }
            forecast.reference = this.date
            return forecast
        },
        bSelectDate() {
            return this.forecast === false || Object.keys(this.forecast).length == 0 || this.forecast.defrost === false
        },
        summary() {
            if (this.forecast.summary && this.forecast.sales_forecast) {
                var current_level = false
                var current_forecast = 0

                if (true || (moment(this.date).isSame(moment(), 'day') && this.forecast.pmix_real)) {
                    var key = false
                    if (moment(this.date).isSame(moment(), 'day')) {
                        key = moment(Math.floor(moment().unix() / 1800) * 1800 * 1000).format('YYYY-MM-DD HH:mm:ss')
                    } else {
                        key = moment(Math.floor(moment(this.date).add('days', 1).unix() / 1800) * 1800 * 1000).format('YYYY-MM-DD HH:mm:ss')
                    }

                    for (var idx in this.forecast.sales_forecast.general.sales_forecast) {
                        if (idx < key) {
                            current_forecast += this.forecast.sales_forecast.general.sales_forecast[idx]
                            // current_forecast += this.forecast.sales_forecast.delivery.sales_forecast[idx]
                        }
                    }

                    current_forecast = Math.round(current_forecast, 2)
                }

                var current_sales = this.forecast.sales_real ? this.forecast.sales_real.general.total : 0

                return {
                    date: moment(this.forecast.summary.ref_general).isValid() ? moment(this.forecast.summary.ref_general).format('YYYY-MM-DD') : this.forecast.summary.ref_general,
                    date_delivery: moment(this.forecast.summary.ref_delivery).isValid() ? moment(this.forecast.summary.ref_delivery).format('YYYY-MM-DD') : this.forecast.summary.ref_delivery,
                    base: this.forecast.summary.sales_base,
                    forecast: Math.round(this.forecast.summary.forecast_total),
                    current_forecast: current_forecast,
                    current_sales: current_sales,
                    last_update: this.forecast.sales_real && this.forecast.sales_real.general.last_update ? moment(this.forecast.sales_real.general.last_update).add(1, 'hours').format('DD/MM/YYYY HH:mm') : false,
                    diff: current_sales - current_forecast,
                    current: this.forecast.sales_real ? this.forecast.sales_real.general.total : 0,
                    current_delta: Math.round(((current_sales - current_forecast) / current_forecast) * 100)
                }
            }
            return {}
        },
        configuration() {
            return this.$store.getters['production/getConfiguration']
        }
    },
    methods: {
        switchFilter(type) {
            this.tabValue = type
            this.$store.commit('production/selectCategory', false)
        },
        load() {
            let self = this

            self.selectCategory(false)

            if (this.products.length == 0) {
                this.$overlay.loading()
                this.$store.dispatch('production/loadData').then(() => {
                    self.selectCategory(false)
                    self.$overlay.hide()
                    this.loadForecast()
                })
            } else {
                this.loadForecast()
            }
        },
        loadForecast() {
            let self = this
            this.$overlay.loading()
            this.$store.dispatch('production/loadForecast', this.date).then(() => {
                self.$overlay.hide()
            })
        },
        selectCategory(category) {
            var self = this
            self.$overlay.loading()
            this.$store.commit('production/selectCategory', category)
            self.$overlay.hide()
        },
        selectDate(date) {
            this.date = moment(date).format('YYYY-MM-DD')
            this.loadForecast()
        }
    },
    updated() {},
    created() {
        this.load()
    },
    mounted() {},
    watch: {}
}
</script>

<style lang="scss" scoped>
#production {
    display: flex;
    x-justify-content: space-around;
    x-align-items: stretch;
    width: 100%;

    #sidebar {
        width: 325px;
        flex: 0 0 325px;
        height: 100%;
        background-color: #fff;

        position: absolute;
    }
    #board {
        padding: 16px;
        border-radius: 4px;
        width: calc(100% - 325px);
        flex: 0 0 calc(100% - 325px);
        position: absolute;
        left: 325px;
        height: 100%;
        min-height: 0;
        min-width: 0;

        header {
            &.header-container {
                margin-bottom: 8px;
            }
        }

        .main-content {
            width: 100%;
            height: calc(100% - 45px);
            //overflow-y: auto;
            // position: absolute;
            // height: calc(100% - 50px - 16px);

            &.without-tab {
                height: calc(100%);
            }

            .working-on {
                @include display-flex();
                @include align-items();
                @include justify-content();
                padding: 1rem;
                width: 100%;
                height: 90%;
                text-transform: uppercase;
                font-family: $text-bold;

                .image {
                    @include background($image: img('alexhead_08.svg'));
                    display: block;
                    width: 100px;
                    height: 100px;
                }
            }
        }
    }
}
</style>
