<template>
    <div id="production-init">
        <!--Para el dia actual-->
        <div v-if="step == 1" class="channels" :class="{ loaded: currentDayCompleted, loading: bTodayLoading }">
            <div class="forecast-defrost">{{ $t('production.select.forecast_title') }}</div>

            <div v-if="sales" class="info">
                <div class="date">{{ formattedDate(date) }}</div>
                <div class="row">
                    <span class="label">{{ $t('production.select.sale') }}</span
                    ><span class="value">{{ Math.round(sales.general.sales_forecast.total + sales.delivery.sales_forecast.total) }}€</span>
                </div>
            </div>

            <div class="content">
                <div class="item">
                    <div class="name">{{ $t('production.select.restaurant') }}</div>
                    <div class="selector">
                        <select v-model="currentGeneral" class="input" @change="changeType($event, 'general')">
                            <option value="weekday">{{ $t('production.select.last') }} {{ formattedDate(weekdayGeneral) }}</option>
                            <option value="custom">{{ $t('production.select.select-date') }}</option>
                        </select>
                        <input v-if="currentGeneral == 'custom'" v-model="dateGeneral" class="input date" type="date" @change="changeDate($event, 'general')" :max="maxDate" :min="minDate" />
                    </div>
                    <template v-if="sales">
                        <div class="chart">
                            <ChartSales :series1="sales.general.sales_reference" :series2="sales.general.sales_forecast.sales_forecast"></ChartSales>
                        </div>
                        <div class="summary">
                            <div class="sales">
                                {{ $t('production.select.sale') }} <span>{{ Math.round(sales.general.sales_forecast.total) }}€</span>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="item">
                    <div class="name">{{ $t('production.select.delivery') }}</div>

                    <div class="selector">
                        <select v-model="currentDelivery" class="input" @change="changeType($event, 'delivery')">
                            <option value="weekday">{{ $t('production.select.last') }} {{ formattedDate(weekdayGeneral) }}</option>
                            <option value="custom">{{ $t('production.select.select-date') }}</option>
                        </select>
                        <input v-if="currentDelivery == 'custom'" v-model="dateDelivery" class="input date" type="date" @change="changeDate($event, 'delivery')" :max="maxDate" :min="minDate" />
                    </div>

                    <template v-if="sales">
                        <div class="chart">
                            <ChartSales :series1="sales.delivery.sales_reference" :series2="sales.delivery.sales_forecast.sales_forecast"></ChartSales>
                        </div>
                        <div class="summary">
                            <div class="sales">
                                {{ $t('production.select.sale') }} <span>{{ Math.round(sales.delivery.sales_forecast.total) }}€</span>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <!--Para el siguiente dia-->
        <div v-else class="channels defrost" :class="{ loading: bTomorrowLoading }">
            <div class="forecast-defrost">{{ $t('production.select.forecast_title_defrost') }}</div>

            <div v-if="defrostSales" class="info">
                <div class="date">{{ formattedDate(dateDefrost) }}</div>
                <div class="row">
                    <span class="label">{{ $t('production.select.sale') }}</span
                    ><span class="value">{{ Math.round(defrostSales.general.sales_forecast.total + defrostSales.delivery.sales_forecast.total) }}€</span>
                </div>
            </div>

            <div class="content">
                <div class="item">
                    <div class="name forecastDefrost">{{ $t('production.select.restaurant') }}</div>
                    <div class="selector">
                        <select v-model="currentGeneralDefrost" class="input" @change="changeType($event, 'generalDefrost')">
                            <option value="weekday">{{ $t('production.select.last') }} {{ formattedDate(weekdayDefrost) }}</option>
                            <option value="custom">{{ $t('production.select.select-date') }}</option>
                        </select>
                        <input v-if="currentGeneralDefrost == 'custom'" v-model="dateGeneralDefrost" class="input date" type="date" @change="changeDate($event, 'generalDefrost')" :max="maxDate" :min="minDate" />
                    </div>
                    <template v-if="defrostSales">
                        <div class="chart">
                            <ChartSales :series1="defrostSales.general.sales_reference" :series2="defrostSales.general.sales_forecast.sales_forecast"></ChartSales>
                        </div>
                        <div class="summary">
                            <div class="sales">
                                {{ $t('production.select.sale') }} <span>{{ Math.round(defrostSales.general.sales_forecast.total) }}€</span>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="item">
                    <div class="name forecastDefrost">{{ $t('production.select.delivery') }}</div>
                    <div class="selector">
                        <select v-model="currentDeliveryDefrost" class="input" @change="changeType($event, 'deliveryDefrost')">
                            <option value="weekday">{{ $t('production.select.last') }} {{ formattedDate(weekdayDefrost) }}</option>
                            <option value="custom">{{ $t('production.select.select-date') }}</option>
                        </select>
                        <input v-if="currentDeliveryDefrost == 'custom'" v-model="dateDeliveryDefrost" class="input date" type="date" @change="changeDate($event, 'deliveryDefrost')" :max="maxDate" :min="minDate" />
                    </div>

                    <template v-if="defrostSales">
                        <div class="chart">
                            <ChartSales :series1="defrostSales.delivery.sales_reference" :series2="defrostSales.delivery.sales_forecast.sales_forecast"></ChartSales>
                        </div>
                        <div class="summary">
                            <div class="sales">
                                {{ $t('production.select.sale') }} <span>{{ Math.round(defrostSales.delivery.sales_forecast.total) }}€</span>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <template v-if="step == 1">
            <div class="btnExit">
                <!-- <button class="btn cancel" @click="reset()">{{ $t('production.select.change_date') }}</button> -->
                <button class="btn step next" :class="{ disabled: !((currentGeneral == 'weekday' || dateGeneral != dateDefault) && (currentDelivery == 'weekday' || dateDelivery != dateDefault)) }" @click="changeStep(2)">{{ $t('production.select.next') }}</button>
            </div>
        </template>
        <template v-else>
            <div class="btnExit">
                <button class="btn step prev" @click="changeStep(1)">{{ $t('production.select.previous') }}</button>
                <button class="btn save-changes" :class="{ disabled: !((currentGeneral == 'weekday' || dateGeneral != dateDefault) && (currentDelivery == 'weekday' || dateDelivery != dateDefault)) }" @click="confirmForecast()">{{ $t('production.select.confirm') }}</button>
            </div>
        </template>
    </div>
</template>

<script>
import ChartSales from './chartSales.vue'

export default {
    components: {
        ChartSales
    },
    name: 'ProductionInit',
    props: { forecast: { type: Object } },
    data() {
        return {
            date: moment().format('YYYY-MM-DD'),
            dateDefrost: moment(this.date).add(1, 'days').format('YYYY-MM-DD'),
            dateDefault: moment(this.date).locale('en').format('dddd').toLowerCase(),
            dateGeneral: moment(this.date).locale('en').format('dddd').toLowerCase(),
            dateDelivery: moment(this.date).locale('en').format('dddd').toLowerCase(),
            dateDefaultDefrost: moment(this.date).add(1, 'days').locale('en').format('dddd').toLowerCase(),
            dateGeneralDefrost: moment(this.date).add(1, 'days').locale('en').format('dddd').toLowerCase(),
            dateDeliveryDefrost: moment(this.date).add(1, 'days').locale('en').format('dddd').toLowerCase(),
            weekdayGeneral: moment(this.date).locale('en').format('dddd').toLowerCase(),
            weekdayDefrost: moment(this.date).add(1, 'days').locale('en').format('dddd').toLowerCase(),
            maxDate: moment().format('YYYY-MM-DD'),
            minDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
            currentGeneral: 'weekday',
            currentDelivery: 'weekday',
            currentGeneralDefrost: 'weekday',
            currentDeliveryDefrost: 'weekday',
            forecastLoaded: null,
            changedTodayReferences: true,
            changedTomorrowReferences: true,
            currentDayCompleted: false,
            bTodayLoading: false,
            bTomorrowLoading: false,
            step: 1
        }
    },
    computed: {
        sales() {
            return this.$store.getters['production/getSales']
        },
        defrostSales() {
            return this.$store.getters['production/getDefrostSales']
        }
    },
    created() {
        this.updateDates(this.forecast)
        this.load()
    },
    methods: {
        load() {
            let self = this

            var params = {
                dateref: this.date,
                date1: this.dateGeneral,
                date2: this.dateDelivery
            }
            // if (this.changedTodayReferences) {
            if (this.step == 1 && this.changedTodayReferences) {
                this.$overlay.loading()
                this.$store.dispatch('production/loadSales', params).then(function () {
                    self.$overlay.hide()
                    self.changedTodayReferences = false
                })
            }
            var defrostParams = {
                dateref: this.dateDefrost,
                date1: this.dateGeneralDefrost,
                date2: this.dateDeliveryDefrost
            }
            // if (this.changedTomorrowReferences) {
            if (this.step == 2 && this.changedTomorrowReferences) {
                this.$overlay.loading()
                this.$store.dispatch('production/loadDefrostSales', defrostParams).then(function () {
                    self.$overlay.hide()
                    self.changedTomorrowReferences = false
                })
            }
        },
        changeType(e, channel) {
            if (e.target.value == 'weekday') {
                if (channel == 'general') {
                    this.dateGeneral = this.dateDefault
                } else if (channel == 'delivery') {
                    this.dateDelivery = this.dateDefault
                } else if (channel == 'generalDefrost') {
                    this.dateGeneralDefrost = this.dateDefaultDefrost
                } else if (channel == 'deliveryDefrost') {
                    this.dateDeliveryDefrost = this.dateDefaultDefrost
                }
                this.load()
            }
        },
        changeDate(e, channel) {
            if (channel == 'general') {
                this.changedTodayReferences = true
                this.dateGeneral = e.target.value
                if (this.dateDelivery == this.dateDefault) {
                    this.currentDelivery = 'custom'
                    this.dateDelivery = this.dateGeneral
                }
            } else if (channel == 'delivery') {
                this.changedTodayReferences = true
                this.dateDelivery = e.target.value
            } else if (channel == 'generalDefrost') {
                this.changedTomorrowReferences = true
                this.dateGeneralDefrost = e.target.value
                if (this.dateDeliveryDefrost == this.dateDefaultDefrost) {
                    this.currentDeliveryDefrost = 'custom'
                    this.dateDeliveryDefrost = this.dateGeneralDefrost
                }
            } else if (channel == 'deliveryDefrost') {
                this.changedTomorrowReferences = true
                this.dateDeliveryDefrost = e.target.value
            }

            this.load()
        },
        changeStep(step) {
            this.step = step
            this.load()
        },
        confirmForecast() {
            var self = this

            this.$popup.confirm({
                message: self.$t('production.select.popup_message'),
                textSave: self.$t('production.select.confirm'),
                textCancel: self.$t('config.printer.cancel'),
                callSave: async function () {
                    try {
                        // Cerramos el popup de confirmación
                        self.$popup.close()

                        // Esperamos un momento antes de mostrar el siguiente popup
                        await new Promise((resolve) => setTimeout(resolve, 300))

                        const popupTimer = setTimeout(() => {
                            self.$popup.close()
                            self.$popup.projectionReturn()
                        }, 40000)

                        // Forzamos la creación del popup de proyección
                        self.$nextTick(() => {
                            self.$popup.projection({
                                closable: false // Evita que se pueda cerrar manualmente
                            })
                        })

                        var data = {
                            ref: self.date,
                            params: {
                                general: self.dateGeneral,
                                delivery: self.dateDelivery,
                                general_defrost: self.dateGeneralDefrost,
                                delivery_defrost: self.dateDeliveryDefrost
                            }
                        }

                        // Realizamos las operaciones
                        await self.$store.dispatch('production/confirm', data)
                        await self.$store.dispatch('production/loadForecast', self.date)
                        clearTimeout(popupTimer)

                        // Cerramos el popup de proyección
                        self.$popup.close()
                    } catch (error) {
                        console.error('Error en confirmForecast:', error)
                        self.$popup.close()
                    }
                },
                callCancel: function () {
                    self.$popup.close()
                }
            })
        },
        formatDate(date) {
            if (this.isValidDate(date)) {
                return moment(date).locale(localStorage.language).format('dddd').toUpperCase() + ' ' + moment(date).format('DD/MM')
            } else {
                const localWeekdays = moment.weekdays()
                const englishWeekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

                const weekdayIndex = englishWeekdays.indexOf(date)

                return localWeekdays[weekdayIndex].toUpperCase()
            }
        },
        isValidDate(dateString) {
            return moment(dateString, 'YYYY-MM-DD', true).isValid()
        },
        formattedDate(date) {
            return this.formatDate(date)
        },
        formatReferenceDay(date) {
            const localWeekdays = moment.weekdays()
            const englishWeekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

            const weekdayIndex = englishWeekdays.indexOf(date)

            return localWeekdays[weekdayIndex].toLowerCase()
        },
        updateDates(forecast) {
            this.date = forecast.reference
            this.dateDefrost = moment(this.date).add(1, 'days').format('YYYY-MM-DD')
            this.dateDefault = moment(this.date).locale('en').format('dddd').toLowerCase()
            this.dateGeneral = moment(this.date.date).locale('en').format('dddd').toLowerCase()
            this.dateDelivery = moment(forecast).locale('en').format('dddd').toLowerCase()
            this.weekday = moment(this.date).locale('en').format('dddd').toLowerCase()
            this.weekdayGeneral = moment(this.date).locale('en').format('dddd').toLowerCase()
            this.weekdayDefrost = moment(this.date).add(1, 'days').locale('en').format('dddd').toLowerCase()

            this.dateDefaultDefrost = moment(this.date).add(1, 'days').locale('en').format('dddd').toLowerCase()
            this.dateGeneralDefrost = moment(this.date).add(1, 'days').locale('en').format('dddd').toLowerCase()
            this.dateDeliveryDefrost = moment(this.date).add(1, 'days').locale('en').format('dddd').toLowerCase()
            this.changedTodayReferences = true
            this.changedTomorrowReferences = true

            this.step = 1

            if (forecast && forecast.summary) {
                this.dateGeneral = forecast.summary.ref_general
                this.dateDelivery = forecast.summary.ref_delivery
                this.currentDayCompleted = true

                if (this.isValidDate(this.dateGeneral)) {
                    this.currentGeneral = 'custom'
                } else {
                    this.currentGeneral = 'weekday'
                }
                if (this.isValidDate(this.dateDelivery)) {
                    this.currentDelivery = 'custom'
                } else {
                    this.currentDelivery = 'weekday'
                }
            } else {
                this.currentDayCompleted = false
                this.dateGeneral = moment(this.date).locale('en').format('dddd').toLowerCase()
                this.dateDelivery = moment(this.date).locale('en').format('dddd').toLowerCase()
            }
        }
    },
    watch: {
        forecast(newValue, oldValue) {
            if (newValue != oldValue) {
                this.updateDates(newValue)
                this.load()
            }
        }
    }
}
</script>
<style lang="scss" scoped>
#production-init {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 0;
    overflow: hidden;
    white-space: nowrap;

    .title {
        font-family: $text-bold;
        @include font-size(l);
        margin-bottom: 64px;
        text-align: center;
        margin: 0.8rem 0 1rem 0;
    }
    .channels {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        position: relative;
        justify-content: space-around;

        &.loaded {
            // background-color: $color-primary-100;
            // margin-bottom: 0.7em;
            // height: 100%;
        }
        &.loading {
            &:before {
                content: ' ';
                @include background($image: img('loader_light.svg'), $size: 60px, $position: center center);
                background-color: rgba(0, 0, 0, 0.5);
                width: 100%;
                height: 100%;
                z-index: 10;
                position: absolute;
            }
        }

        .info {
            width: 100%;
            display: flex;
            width: 100%;
            padding: 8px;
            border-radius: 4px;
            background-color: #fff;
            justify-content: space-between;

            .date {
                padding: 8px;
                padding-left: 35px;
                @include background($image: img('input_date_cccccc.svg'), $size: 25px, $position: left center);
            }
            .row {
                padding: 8px;
                .value {
                    font-family: $text-bold;
                    margin-left: 8px;
                }
            }
        }

        .forecast-defrost,
        .necessary,
        .forecast-selected {
            @include font-size(l);
            width: 100%;
            text-align: center;
            font-family: $text;
        }
        .necessary {
            @include font-size(s);
            padding: 0;
            margin: 0;
        }
        .title {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 0;
            margin: 0;
            margin-top: 8px;
            font-family: $text-bold;

            .forecast-selected {
                // color: $color-primary-500;
                width: auto;
            }
            .icon {
                margin-left: 5px;
                @include background($image: img('check-circle.svg'), $position: center center);
                height: 1.125rem;
                width: 1.125rem;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
        .forecast-defrost {
            padding-top: 8px;
            font-family: $text-bold;
            // color: $color-secondary-500;
        }

        .content {
            width: 100%;
            display: flex;
            flex-direction: row;
            padding-bottom: 0;
            .item {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 8px 4px;
                padding-bottom: 0;
                padding-top: 4px;
                align-items: center;

                .name {
                    font-family: $text-bold;
                    @include font-size(m);
                    margin-bottom: 16px;
                    text-align: center;
                    text-transform: uppercase;
                    &.forecastDefrost {
                        margin-bottom: 8px;
                    }
                }
                .selector {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    gap: 10px;
                    &.weekday {
                        justify-content: center;
                    }

                    .input {
                        /* display: flex;
                        flex-direction: column;  */
                        width: 100%;
                        height: 45px;
                        padding: 4px;
                        padding-left: 12px;
                        border: 1px solid #ccc;
                        border-radius: 3px;
                        color: $color-black;
                        justify-content: center;
                        line-height: 20px;
                    }
                }
                .summary {
                    margin-top: 8px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-content: stretch;
                    justify-content: space-around;
                    align-items: center;
                    width: 100%;

                    .sales,
                    .reference-date {
                        font-family: $text;
                        text-align: center;
                        span {
                            font-family: $text-bold;
                            @include font-size(s);
                        }
                    }
                }
                .chart {
                    width: 100%;
                    height: 250px;
                    background-color: #fff;
                    border-radius: 3px;
                    float: none;
                    margin-top: 8px;
                    overflow: hidden;
                }
            }
        }
    }

    .btnExit {
        height: 10%;
        margin-top: 8px;
        .btn {
            padding: 0px;
            &.save-changes {
                height: 70%;
            }
            &.cancel,
            &.prev {
                background-color: $color-neutral-400;
                font-family: $text;
                color: $color-black;
            }
        }
    }
}
</style>
